const projectData = [
  {
    id: 0,
    projectName: "road to hero",
    projectNameDescription:
      "novel’s website that provides a better reading experience.",
    projectDescription:
      "A novel's website designed to deliver an enhanced reading experience by seamlessly retrieving story chapters from a Discord server, where the novel is originally written, using a custom Discord bot. The chapters are displayed in an organized and sequential manner, ensuring ease of navigation. Additionally, the website offers features that further enrich the user experience, such as tracking reading progress and allowing users to reset it as needed.",
    projectImage: "./images/projects/road-to-hero.webp",
    projectRepo: false,
    projectLive: "https://road-to-hero.vercel.app/",
    technologies: [
      {
        name: "react.js",
        icon: "FaReact",
      },
      {
        name: "next.js",
        icon: "SiNextdotjs",
      },
      {
        name: "TypeScript",
        icon: "SiTypescript",
      },
      {
        name: "tailwindCSS",
        icon: "SiTailwindcss",
      },
      {
        name: "node.js",
        icon: "SiNodedotjs",
      },
      {
        name: "vercel",
        icon: "SiVercel",
      },
    ],
    isNew: true,
  },
  {
    id: 1,
    projectName: "team website",
    projectNameDescription:
      "a website template that can be a good fit for a company or a product.",
    projectDescription:
      "A versatile website template tailored for any business, offering a wide range of features to showcase your brand effectively. It includes a landing section for introductions, an articles section to highlight featured content, a gallery section to display key images of your business, and a features section to outline the services you provide. Additionally, it features a testimonials section to share customer feedback, a team members section to recognize contributions, and much more, making it an all-in-one solution for businesses.",
    projectImage: "./images/projects/team-website.webp",
    projectRepo: "https://github.com/Mohamed-Waled/webSite",
    projectLive: "https://landing-page.mohamedwaled.com/",
    technologies: [
      {
        name: "html5",
        icon: "SiHtml5",
      },
      {
        name: "css3",
        icon: "SiCss3",
      },
      {
        name: "SASS",
        icon: "SiSass",
      },
      {
        name: "vanilla JavaScript",
        icon: "SiJavascript",
      },
    ],
    featured: true,
  },
  {
    id: 2,
    projectName: "clipboard landing page",
    projectNameDescription:
      "a landing page template built for training purposes.",
    projectDescription:
      "A sleek and organized clipboard-style landing page that serves as an ideal homepage for your business, application, or portfolio. Its clean and modern design ensures clarity and adaptability, allowing you to customize it to suit your specific needs.",
    projectImage: "./images/projects/clipboard-landing-page.webp",
    projectRepo: "https://github.com/Mohamed-Waled/Clipboard-Landing-Page",
    projectLive: "https://mohamed-waled.github.io/Clipboard-Landing-Page/",
    technologies: [
      {
        name: "html5",
        icon: "SiHtml5",
      },
      {
        name: "css3",
        icon: "SiCss3",
      },
    ],
    isTraining: true,
  },
  {
    id: 3,
    projectName: "personal portfolio webpage",
    projectNameDescription: "a personal portfolio built for training purposes.",
    projectDescription:
      "A personal portfolio webpage designed to include everything you need to showcase your work effectively. It features a section to introduce yourself, a dedicated area to display your projects, and the option to add detailed pages for each project to provide in-depth information. The design is rounded out with a footer section that links to all your social media accounts, ensuring easy connectivity.",
    projectImage: "./images/projects/personal-portfolio-webpage.webp",
    projectRepo: "https://github.com/Mohamed-Waled/Personal-Portfolio-Webpage",
    projectLive: "https://mohamed-waled.github.io/Personal-Portfolio-Webpage/",
    technologies: [
      {
        name: "html5",
        icon: "SiHtml5",
      },
      {
        name: "css3",
        icon: "SiCss3",
      },
    ],
    isTraining: true,
  },
  {
    id: 4,
    projectName: "fylo landing page",
    projectNameDescription:
      "a landing page template built for training purposes.",
    projectDescription:
      "A Fylo-inspired landing page featuring a clean two-column layout, perfect for use as a homepage for your business, application, or portfolio. Its tidy and modern design ensures clarity and professionalism, while offering the flexibility to customize and add content to suit your needs.",
    projectImage: "./images/projects/fylo-landing-page.webp",
    projectRepo:
      "https://github.com/Mohamed-Waled/Fylo-Landing-Page-With-Two-Column-Layout",
    projectLive:
      "https://mohamed-waled.github.io/Fylo-Landing-Page-With-Two-Column-Layout/",
    technologies: [
      {
        name: "html5",
        icon: "SiHtml5",
      },
      {
        name: "css3",
        icon: "SiCss3",
      },
    ],
    isTraining: true,
  },
  {
    id: 5,
    projectName: "technical documentation page",
    projectNameDescription:
      "a website template that can be a good fit for technical documentation.",
    projectDescription:
      "A versatile technical documentation page that can be easily customized to serve as a terms of use or privacy policy page for your website. It offers flexibility to include and organize any additional information you need.",
    projectImage: "./images/projects/technical-documentation-page.webp",
    projectRepo:
      "https://github.com/Mohamed-Waled/Technical-Documentation-Page",
    projectLive:
      "https://mohamed-waled.github.io/Technical-Documentation-Page/",
    technologies: [
      {
        name: "html5",
        icon: "SiHtml5",
      },
      {
        name: "css3",
        icon: "SiCss3",
      },
    ],
    isTraining: true,
  },
  {
    id: 6,
    projectName: "random quote machine",
    projectNameDescription:
      "a quote machine that randomizes quotes from a certain API.",
    projectDescription:
      "A random quote generator that fetches quotes from an API and displays them dynamically, offering an engaging and seamless user experience.",
    projectImage: "./images/projects/random-quote-machine.webp",
    projectRepo: "https://github.com/Mohamed-Waled/Random-Quote-Machine",
    projectLive: "https://random-quote-machine-lyart.vercel.app/",
    technologies: [
      {
        name: "react.js",
        icon: "FaReact",
      },
      {
        name: "SASS",
        icon: "SiSass",
      },
    ],
    isTraining: true,
  },
  {
    id: 7,
    projectName: "Real Pros",
    projectNameDescription: "call center company website.",
    projectDescription:
      "A professional website for a call center company, designed to serve as a bridge between the company and potential customers. It simplifies communication and provides easy access to essential information, ensuring a seamless user experience.",
    projectImage: "./images/projects/real-pros.webp",
    projectRepo: false,
    projectLive: "https://callcenter-company.mohamedwaled.com/",
    technologies: [
      {
        name: "react.js",
        icon: "FaReact",
      },
      {
        name: "next.js",
        icon: "SiNextdotjs",
      },
      {
        name: "SASS",
        icon: "SiSass",
      },
      {
        name: "Frame Motion",
        icon: "SiFramer",
      },
    ],
    featured: true,
  },
];

export default projectData;
