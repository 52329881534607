import { useEffect } from "react";
import Aos from "aos";

import "aos/dist/aos.css";
import Home from "./components/home/Home";

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
