import './badge.scss';

const Badge = ({ type }) => {
  return (
    <div className={`badge ${type.toLowerCase()}`}>
      {type}
    </div>
  );
};

export default Badge;
