import { FaUserAlt, FaEnvelope } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiFillMessage } from "react-icons/ai";
import {useState, useRef} from "react";
import emailjs from "@emailjs/browser";

import "./contactForm.scss";
import "react-toastify/dist/ReactToastify.css";

function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7crjlwf",
        "template_a8q5dff",
        form.current,
        "kAUgEbglN9PMSLyaU"
      )
      .then(
        (result) => {
          toast.success("Thanks, I've Received Your Mail", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          toast.error("There Is An Error Occurred!!", {
            position: "bottom-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    setName("");
    setEmail("");
    setMessage("");
  };
  return (
    <div className="contactForm" id="contactForm">
      <div className="container">
        <div className="box">
          <div className="text" data-aos="slide-right">
            <div className="mainTitle">
              <h1>contact form</h1>
            </div>
            <div className="quote">
              <h2>have a design? want it to see the light?</h2>
            </div>
            <div className="description">
              <p>
                Just send me an E-mail, I got your back. I know what you exactly
                want, and I will get it done for you.
              </p>
              <p>Don't hesitate.</p>
              <br />
              <p>Are you having a problem with the form?</p>
              <div className="problem">
                Click Here
                <div className="icon">
                  <AiOutlineArrowRight />
                </div>
                <a href="mailto:mohamedwaled8642@gmail.com">
                  <div className="icon emailIcon">
                    <FaEnvelope />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="form" data-aos="slide-left" data-aos-offset="100">
            <form ref={form} onSubmit={sendEmail}>
              <div className="field">
                <div className="icon">
                  <FaUserAlt />
                </div>
                <input
                  type="text"
                  id="name"
                  className="input"
                  required
                  autoComplete="off"
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <label htmlFor="name" className="label">
                  name
                </label>
              </div>
              <div className="field">
                <div className="icon">
                  <FaEnvelope />
                </div>
                <input
                  type="email"
                  id="email"
                  className="input"
                  required="Your Email Isn't Valid"
                  autoComplete="off"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <label htmlFor="email" className="label">
                  e-mail
                </label>
              </div>
              <div className="field">
                <div className="icon messageIcon">
                  <AiFillMessage />
                </div>
                <textarea
                  id="message"
                  className="input message"
                  required
                  name="message"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
                <label htmlFor="message" className="label messageLabel">
                  your message
                </label>
              </div>
              <input type="submit" className="button" value="submit" />
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default ContactForm;
