import "./break.scss";

function Break({ children }) {
  return (
    <div className="break">
      <div data-aos="zoom-in"  className="content">
    {children}
      </div>
    </div>
  );
}

export default Break;
