import { useState, useEffect } from "react";

import "./clock.scss";

function Clock() {
  const [localTime, setLocalTime] = useState("");
  const [localDate, setLocalDate] = useState("");

  const getEETOffset = () => {
    // Get current date
    const now = new Date();
    const year = now.getFullYear();

    // Find last Friday of April (EEST starts)
    const aprilEnd = new Date(year, 3, 30); // April 30th
    let lastFridayApril = new Date(aprilEnd);
    while (lastFridayApril.getDay() !== 5) { // 5 is Friday
      lastFridayApril.setDate(lastFridayApril.getDate() - 1);
    }
    const eestStart = new Date(lastFridayApril.setHours(0, 0, 0, 0));

    // Find last Thursday of October (EET starts)
    const octEnd = new Date(year, 9, 31); // October 31st
    let lastThursdayOct = new Date(octEnd);
    while (lastThursdayOct.getDay() !== 4) { // 4 is Thursday
      lastThursdayOct.setDate(lastThursdayOct.getDate() - 1);
    }
    const eetStart = new Date(lastThursdayOct.setHours(23, 59, 59, 999));

    // Check if current time is in EEST
    if (now >= eestStart && now <= eetStart) {
      return 3; // EEST: UTC+3
    } else {
      return 2; // EET: UTC+2
    }
  };

  const updateTime = () => {
    const offset = getEETOffset();
    const now = new Date();
    const utc = now.getTime() + now.getTimezoneOffset() * 60000;
    const newDate = new Date(utc + 3600000 * offset);

    setLocalTime(
      newDate.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    );

    setLocalDate(
      newDate.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    );
  };

  useEffect(() => {
    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="clock">
      <h2 className="title">
        my local <span>time</span> and <span>date</span> (
        {getEETOffset() === 3 ? "EEST" : "EET"})
      </h2>
      <div className="timedate">{localDate}</div>
      <div className="timedate">{localTime}</div>
    </div>
  );
}

export default Clock;
