import "./landing.scss";

function Landing() {
  const date = new Date();
  const longMonth = date.toLocaleString("en-us", { month: "long" });

  const getSeasonalImage = () => {
    const SEASONS = {
      spring: {
        src: "./images/seasonal/spring-flower.webp",
        alt: "Spring Flower",
        id: "spring",
        startDate: { month: 3, day: 20 },
        endDate: { month: 6, day: 20 },
      },
      summer: {
        src: "./images/seasonal/summer-leaf.webp",
        alt: "Summer Leaf",
        id: "summer",
        startDate: { month: 6, day: 21 },
        endDate: { month: 9, day: 21 },
      },
      autumn: {
        src: "./images/seasonal/autumn-leaf.webp",
        alt: "Autumn Leaf",
        id: "autumn",
        startDate: { month: 9, day: 22 },
        endDate: { month: 12, day: 20 },
      },
      winter: {
        src: "./images/seasonal/snowflake.webp",
        alt: "Winter Snowflake",
        id: "winter",
        startDate: { month: 12, day: 21 },
        endDate: { month: 3, day: 19 },
      },
    };

    const date = new Date();
    const month = date.getMonth() + 1
    const day = date.getDate();

    const isCurrentDateInRange = (start, end) => {
      if (end.month < start.month) {
        return (
          month > start.month ||
          (month === start.month && day >= start.day) ||
          month < end.month ||
          (month === end.month && day <= end.day)
        );
      }
      return (
        (month > start.month || (month === start.month && day >= start.day)) &&
        (month < end.month || (month === end.month && day <= end.day))
      );
    };

    const currentSeason = Object.values(SEASONS).find((season) =>
      isCurrentDateInRange(season.startDate, season.endDate)
    );

    return currentSeason || SEASONS.winter;
  };

  function scrollToSection() {
    const header = document.getElementById("header");
    header.scrollIntoView();
  }

  return (
    <div className="body">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <div className="landingText">
        <p className="greet">
          hello, i'm <span className="name">mohamed waled</span>.
        </p>
        <p className="greet">i'm a front-end web developer.</p>
        <div className="button" onClick={scrollToSection}>
          curious ?
        </div>
      </div>
      <div className="glass" data-aos="slide-right">
        available in {longMonth}
        <img
          id={getSeasonalImage().id}
          src={getSeasonalImage().src}
          alt={getSeasonalImage().alt}
        />
      </div>
    </div>
  );
}

export default Landing;
