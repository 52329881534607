import { useEffect } from "react";

import "./header.scss";

function Header() {
  useEffect(() => {
    let section = document.querySelectorAll(".section");
    let link = document.querySelectorAll(".link");
    window.addEventListener("scroll", () => {
      section.forEach((sec) => {
        if (section[0].getBoundingClientRect().top > 0) {
          link[0].classList.remove("active");
        }
        if (section[1].getBoundingClientRect().top < 0) {
          link[1].classList.remove("active");
        }
        if (
          sec.getBoundingClientRect().top <= 0 &&
          sec.getBoundingClientRect().bottom >= 0
        ) {
          link.forEach((li) => {
            if (li.innerHTML === sec.id) {
              li.classList.add("active");
            } else {
              li.classList.remove("active");
            }
          });
        }
      });
    });
  });

  return (
    <header className="header" id="header">
      <div className="container">
        <nav className="mainNav">
          <ul className="navOne">
            <li>
              <a href="#about" className="link">
                about
              </a>
            </li>
            <li>
              <a href="#portfolio" className="link">
                portfolio
              </a>
            </li>
          </ul>
          <ul className="animated-button">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <li>
              <a href="#contactForm">say hello</a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
