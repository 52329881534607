import { MdDevicesOther, MdAnimation, MdOutlineCode } from "react-icons/md";
import { RiTimerFlashFill } from "react-icons/ri";

import "./about.scss";

function About() {
  return (
    <div className="aboutSection section" id="about">
      <div className="container">
        <div className="box">
          <div data-aos="slide-right" className="img">
            <img
              src="./images/portfolio.webp"
              alt="mohamed waled the website owner"
            />
          </div>
          <div className="about">
            <div data-aos="slide-left" className="mainTitle">
              <h1>about</h1>
            </div>
            <div data-aos="slide-left" className="jobTitle">
              <h2>front-end web developer</h2>
            </div>
            <div data-aos="slide-left" className="bio">
              <p>
                Hello, I'm Mohamed Waled, a self-taught Front-End Developer who
                began his coding journey in December 2020. Over the years, I've
                sharpened my skills in building responsive, user-friendly web
                applications, with expertise in technologies like Next.js and
                React.js.
              </p>
              <p>
                My ultimate goal is to grow into a proficient Software Engineer,
                delivering innovative solutions and excelling in the
                ever-evolving tech industry.
              </p>
              <p>
                Feel free to explore my projects below to see what I’ve been
                working on, and don’t hesitate to reach out via the "Say Hello"
                button or the contact form at the bottom of the page. I’d love
                to hear from you!
              </p>
              <p>
                Thank you for visiting my portfolio, and I look forward to
                collaborating with you on exciting projects!
              </p>
            </div>
            <div data-aos="fade-up" className="services">
              <div className="icon">
                <RiTimerFlashFill />
                <p className="title">on time</p>
              </div>
              <div className="icon">
                <MdDevicesOther />
                <p className="title">responsive</p>
              </div>
              <div className="icon">
                <MdAnimation />
                <p className="title">animated</p>
              </div>
              <div className="icon">
                <MdOutlineCode />
                <p className="title">clean code</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
