import { SiJavascript, SiNextdotjs, SiTypescript, SiTailwindcss, SiNodedotjs, SiVercel, SiHtml5, SiCss3, SiSass, SiFramer } from "react-icons/si";
import { Navigation, A11y, Autoplay, EffectCoverflow } from "swiper";
import {  AiFillCloseSquare } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaReact, FaGithub } from "react-icons/fa";
import { useState } from "react";
import Modal from "react-modal";

import "swiper/css";
import "./portfolio.scss";
import "swiper/css/navigation";
import Badge from "../Badge/Badge";
import "swiper/css/effect-coverflow";
import projectsData from "./projectsdata";

Modal.setAppElement("#root");
function Portfolio() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(projectsData[0]);

  return (
    <div className="portfolio section" id="portfolio">
      <div className="container" data-aos="fade-up">
        <div className="mainTitle">
          <h1>portfolio</h1>
        </div>
        <Swiper
          modules={[Navigation, A11y, Autoplay, EffectCoverflow]}
          navigation={true}
          loop={true}
          effect={"coverflow"}
          centeredSlides={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              spaceBetween: 50,
              slidesPerView: 3,
            },
          }}
          autoplay={{ delay: 4000 }}
        >
          {projectsData.map((project) => {
            return (
              <SwiperSlide key={project.id}>
                <div className="image">
                  <img src={project.projectImage} alt={project.projectName} />
                  {project.featured && <Badge type="Featured" />}
                  {project.isNew && <Badge type="New" />}
                  {project.isTraining && <Badge type="Training" />}
                </div>
                <div className="topTie">
                  <h2>{project.projectName}</h2>
                  <h4>{project.projectNameDescription}</h4>
                </div>
                <div className="bottomTie">
                  <button
                    className="projectsBotton"
                    onClick={() => {
                      setIsModalOpen(true);
                      setModalData(project);
                    }}
                  >
                    learn more
                  </button>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {modalData ? (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          className="Modal"
          overlayClassName="modalOverLay"
        >
          <div className="modal">
            <div className="closeIcon">
              <AiFillCloseSquare onClick={() => setIsModalOpen(false)} />
            </div>
            <div className="topHeader">
              <h2 className="modalTitle">{modalData.projectName}</h2>
              {modalData.projectRepo ? (
                <div className="icon">
                  <a
                    href={modalData.projectRepo}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaGithub />
                  </a>
                </div>
              ) : (
                <div className="icon disabled" title="Sorry, Private Repo">
                  <FaGithub />
                </div>
              )}
            </div>

            <h3 className="descriptionTitle">what about this website ?</h3>
            <p className="description">{modalData.projectDescription}</p>

            <iframe
              src={modalData.projectLive}
              title={modalData.projectName}
              frameBorder="0"
              className="liveSite"
            />

            <h3 className="descriptionTitle">What technologies i've used ?</h3>
            <div className="technologies">
              {modalData.technologies.map((tech) => {
                return (
                  <div className="tech" key={tech.name}>
                    {tech.icon === "FaReact" ? (
                      <FaReact />
                    ) : tech.icon === "SiSass" ? (
                      <SiSass />
                    ) : tech.icon === "SiHtml5" ? (
                      <SiHtml5 />
                    ) : tech.icon === "SiCss3" ? (
                      <SiCss3 />
                    ) : tech.icon === "SiJavascript" ? (
                      <SiJavascript />
                    ) : tech.icon === "SiNextdotjs" ? (
                      <SiNextdotjs />
                    ) : tech.icon === "SiTypescript" ? (
                      <SiTypescript />
                    ) : tech.icon === "SiTailwindcss" ? (
                      <SiTailwindcss />
                    ) : tech.icon === "SiNodedotjs" ? (
                      <SiNodedotjs />
                    ) : tech.icon === "SiVercel" ? (
                      <SiVercel />
                    ) : tech.icon === "SiFramer" ? (
                      <SiFramer />
                    ) :  (
                      ""
                    )}
                    <p>{tech.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}

export default Portfolio;
